import React from "react";
import { Container, Text } from "@atoms";
import ContentBuilder from "./ContentBuilder";

const PageBody = ({ label, title, contentBuilder }) => {
  return (
    <Container variant="xxs">
      <div className="flex flex-col gap-6 pt-10 pb-10 sm:pt-20">
        {label && (
          <Text className="uppercase leading-normal tracking-0.5 text-teal">
            {label}
          </Text>
        )}
        <Text variant="h1">{title}</Text>
      </div>
      <ContentBuilder blocks={contentBuilder} />
    </Container>
  );
};

PageBody.defaultProps = {};

export default PageBody;
